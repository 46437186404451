import {
    onBeforeUnmount, onMounted, Ref, UnwrapRef,
} from 'vue';

const checkExcludedComponents = (event: Event, excludedComponents: Ref<UnwrapRef<HTMLElement | null>>[]): boolean => {
    const eventPath = event.composedPath();

    return excludedComponents.some((excludedComponent) => event.target === excludedComponent.value || eventPath.includes(excludedComponent.value));
};

export const useClickOutside = (
    component: Ref<UnwrapRef<HTMLElement | null>>,
    callback: () => void,
    excludedComponents?: Ref<UnwrapRef<HTMLElement | null>>[],
): void => {
    if (!component) {
        // eslint-disable-next-line no-console
        console.error('A target component has to be provided.');

        return;
    }

    if (!callback || typeof callback !== 'function') {
        // eslint-disable-next-line no-console
        console.error('A callback has to be provided.');

        return;
    }

    const handleOutsideClick = (event: MouseEvent) => {
        const isTargetComponentOrExcluded = event.target === component.value
            || event.composedPath().includes(component.value)
            || (excludedComponents && checkExcludedComponents(event, excludedComponents));

        if (isTargetComponentOrExcluded) {
            return;
        }

        callback();
    };

    onMounted(() => {
        window.addEventListener('click', handleOutsideClick);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('click', handleOutsideClick);
    });
};
