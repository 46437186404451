import { EventTypeEnum, KeyEnum } from '../enums/enums';
import { useEventListener } from './useEventListener';

export const useModalEscape = (callback: () => void) => {
    const onEscapePressed = (event: KeyboardEvent) => {
        if (event.key === KeyEnum.ESCAPE || event.key === KeyEnum.ESC) {
            callback();
        }
    };

    useEventListener(window, EventTypeEnum.KEYDOWN, onEscapePressed);
};
